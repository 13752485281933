
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const isLoggedIn = computed(() => store.state.user);
        return { isLoggedIn };
    },
});
